@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */
html,
body {
  scrollbar-width: thin;
  font-family: "Poppins", sans-serif;
  font-display: swap;
/* 
  font-family: "Poppins", sans-serif !important;
  scrollbar-width: thin; */
} 

body *{
  scrollbar-width: thin;
}

/* For input type search (this is typically where the reset button appears) */
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
}

/* For Firefox */
input[type="search"]::-moz-search-clear {
  display: none;
}

/* For Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

/* For Edge */
input[type="search"]::-ms-search-cancel-button {
  display: none;
}

::-moz-selection {
  /* Code for Firefox */
  color: #fff;
  background: #ca1582;
}

::selection {
  color: #fff;
  background: #ca1582;
}

.pl-benchmark-sections-axs {
  scroll-margin-top: 90px; /* Adjust this value to match your app bar height */
}